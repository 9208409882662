import React, {  useState } from "react";
import img1 from "../../../assets/aboutus/download_35.69468000000052.svg";
import classes from "./CompanyValuesAndMission.module.css";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";


const array = [
  {
    key: 1,
    number: "1.",
    title: "Excellence",
    description: "We are dedicated to delivering excellence in every project we undertake. Our team of skilled professionals goes the extra mile to ensure top-notch solutions that exceed expectations.    ",
    image: img1,
  },
  {
    key: 2,
    number: "2.",
    title: "Innovation",
    description: "We embrace innovation and continuously seek new ways to harness the power of technology for the benefit of our clients. Our inventive solutions are tailored to meet specific business challenges and drive success.",
    image: img1,
  },
  {
    key: 3,
    number: "3.",
    title: "Customer Centricity",
    description:
      "Our clients are at the heart of everything we do. We actively listen to their needs, understand their goals, and craft solutions that align perfectly with their vision.",
    image: img1,
  },
  {
    key: 4,
    number: "4.",
    title: "Collaboration",
    description:
      "We believe in the power of collaboration and teamwork. By working closely with our clients, we forge strong partnerships that lead to shared success.",
    image: img1,
  },
  {
    key: 5,
    number: "5.",
    title: "Integrity",
    description:
      "Transparency, honesty, and integrity are the cornerstones of our business. We uphold the highest ethical standards and treat every client, employee, and partner with respect.",
    image: img1,
  },
];

const CompanyValuesAndMission = () => {
  const [opacity, setopacity] = useState("seo");

  return (
    <div className={classes.briefintro}>
      <div className={classes.briefintro_con}>
        <div className={classes.headerCont}>
        <div className={classes.header}>
          <h3>OUR VALUES</h3>
          <p>Nested Stacks is a leading software development company committed to delivering cutting-edge solutions that drive business success.</p>
        </div></div>
        <div className={classes.devscroll_con}>
          <div>
            <Controller>
              <Scene triggerHook="onLeave" duration="200%" pin>
                <Timeline>
                  <Tween from={{ y: "10%" }} to={{ y: "-65%" }}>
                    <section className={classes.panel}>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "excellence" ? { opacity: 1 } : { opacity: 0.5 }
                        }
                      >
                      <h3><span style={{color:'#EB4138'}}>01.</span><br/>Excellence</h3><br/>
                      <p className={classes.description}>
                        We are dedicated to delivering excellence in every project we undertake. Our team of skilled professionals goes the extra mile to ensure top-notch solutions that exceed expectations.
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "innovation"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <h3><span style={{color:'#EB4138'}}>02.</span><br/>Innovation</h3><br/>
                      <p className={classes.description}>
                      We embrace innovation and continuously seek new ways to harness the power of technology for the benefit of our clients. Our inventive solutions are tailored to meet specific business challenges and drive success.                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "customer"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <h3><span style={{color:'#EB4138'}}>03.</span><br/>Customer Centricity</h3><br/>
                      <p className={classes.description}>
                      Our clients are at the heart of everything we do. We actively listen to their needs, understand their goals, and craft solutions that align perfectly with their vision.
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "collaboration"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <h3><span style={{color:'#EB4138'}}>04.</span><br/>Collaboration</h3><br/>
                      <p className={classes.description}>
                      We believe in the power of collaboration and teamwork. By working closely with our clients, we forge strong partnerships that lead to shared success.                      
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "integrity"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <h3><span style={{color:'#EB4138'}}>05.</span><br/>Integrity</h3><br/>
                      <p className={classes.description}>
                      Transparency, honesty, and integrity are the cornerstones of our business. We uphold the highest ethical standards and treat every client, employee, and partner with respect.                      
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "integrity"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      </span>
                    </section>
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
          </div>
          <div>
            <Controller>
              <Scene duration="200%" triggerHook="onLeave" pin>
                {(progress) => {
                  if (progress < 0.10) {
                    setopacity("excellence");
                  }
                  if (progress > 0.10 && progress < 0.30) {
                    setopacity("innovation");
                  }
                  if (progress > 0.30 && progress < 0.50) {
                    setopacity("customer");
                  }
                  if (progress > 0.50 && progress < 0.70) {
                    setopacity("collaboration");
                  }
                  if (progress > 0.70) {
                    setopacity("integrity");
                  }

                  return (
                    <section className={classes.testt}>
                      <span className={classes.imgCont}>
                        <img
                          className="image"
                          src={img1}
                          alt=""
                          width='80%'
                        />
                      </span>
                    </section>
                  );
                }}
              </Scene>
            </Controller>
          </div>
        </div><div className={classes.mobile}>
        <div>
            <div style={{ position: "relative",width: 'fit-content', background: 'transparent' }}>
                    <img
                      src={img1}
                      alt=""
                      width="80%"
                      style={{ justifySelf: "center", margin: "auto", background: 'transparent' }}
                    />
                  </div>
            {array.map((data) => {
              return (
        <div className={classes.mobiletext}>
          <h3 style={{position:'relative'}}><span style={{color:'#EB4138'}}>{data.number}</span><br/>{data.title}</h3>
          <p className={classes.description}>{data.description}</p>
        </div>
              );
            })}
        </div>
      </div>
      </div>
    </div>
  );
};

export default CompanyValuesAndMission;
