import React from 'react'
import classes from './Hero.module.css'
import video from '../../../assets/HeroBackground.mp4'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className={classes.HeroContainer}>
      <video autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={classes.CatchyHeadline}>
        <div className={classes.Tagline}>
            <h2>Transforming<br/> Ideas Into Reality</h2>
        </div>
        <div className={classes.PrimaryCTAButton}>
        <Link to='/aboutus' style={{textDecoration: 'none',width: 'fit-content'}}><button><p>LEARN MORE</p> <TrendingFlatIcon style={{height:'1em',position:'relative',alignSelf:'center'}}/></button></Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
