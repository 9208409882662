import React from 'react'
import classes from './Services.module.css'
import MiniOrangeLogo from '../../../assets/MiniOrangeLogo.png'
import WebDevelopment from '../../../assets/WebDevelopment.png'
import MobileApps from '../../../assets/MobileApps.png'
import CustomSoftware from '../../../assets/CustomSoftware.png'
import UIUXDesign from '../../../assets/UIUXDesign.png'
import EmployeeAugmentation from '../../../assets/EmployeeAugmentation.png'
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

const ServicesData = [
    { id: 1, image:WebDevelopment, title:'Web Development', subTitle:'Crafting responsive and user-centric web applications that elevate your online presence.', description: 'Unlock the potential of the digital world with our custom web development services. We design and develop stunning, user-friendly websites that leave a lasting impression on your audience and drive online success.'  },
    { id: 2, image:MobileApps, title:'Mobile Apps', subTitle:'Creating seamless mobile experiences that engage and delight users on all platforms.', description: 'Reach your customers on the go with our top-of-the-line mobile app development services. Our talented app developers create seamless iOS and Android applications that engage users and deliver experiences.' },
    { id: 3, image:CustomSoftware, title:'Custom Software', subTitle:'Tailoring software solutions to meet your unique business needs and challenges.', description: "Our team of skilled developers and engineers crafts bespoke software solutions tailored to your specific business needs. From enterprise resource planning (ERP) systems to customer relationship management (CRM) platforms, we've got you covered." },
    { id: 4, image:UIUXDesign, title:'UI/UX Design', subTitle:'Designing intuitive and captivating user interfaces for exceptional user experiences.', description: "Unlock the potential of the digital world with our custom web development services. We design and develop stunning, user-friendly websites that leave a lasting impression on your audience and drive online success." },
    { id: 5, image:EmployeeAugmentation, title:'Employee Augmentation', subTitle:'Gain access to a skilled pool of professionals through our employee augmentation services.', description: "Offshore companies can rely on our experienced and dedicated team members to augment their workforce and tackle critical projects efficiently." },
  ]
const Services = () => {
  return (
    <div className={classes.servicesContainer} id='portfolio'>
      <div className={classes.heading}>
        <div className={classes.header}>
            <div className={classes.logoo}><img src={MiniOrangeLogo} alt=''/></div>
            <h3 >WHY NESTED STACKS?</h3>
        </div>
        <div className={classes.subTitle}><p style={{width:'100%'}}>A leading software development company committed to delivering cutting-edge solutions that drive business success.</p></div>
      </div>
      <div className={classes.contantContainer}>
      <div className={classes.ServicesDescription}>
        <div style={{position:'relative',height:'fit-content',alignSelf:'center'}}>
        <h3>Unlocking Possibilities through Technology</h3>
        <p>We are driven by a passion for technology and a commitment to transforming businesses through innovative solutions. Since our inception, we have been at the forefront of delivering exceptional software, web, and mobile services to clients worldwide. Our journey began with a vision to bridge the gap between cutting-edge technology and businesses, empowering them to thrive in the digital age.</p></div>
      </div>
      {ServicesData.map((item) => (
        <div key={item.id} className={classes.servicesCard}>
          <img src={item.image} alt='' className={classes.serviceImage}/>
          <h3 style={{width:'90%',marginLeft:'5%'}}>{item.title}</h3>
          <p style={{width:'90%',marginLeft:'5%',color: '#EB4138', marginBottom:'o'}}>{item.subTitle}</p>
          <p style={{width:'90%',marginLeft:'5%',marginTop:'0'}}>{item.description}</p>
        </div>
      ))}
      </div>
      <div className={classes.swiper}>
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            effect={"fade"}
            style={{
              margin: '5% 0 5% 0',
              zIndex: '1',
              // backgroundColor:'red',
              height:'auto' 
            }}
          >
            <SwiperSlide
                  style={{
                    height: "auto",
                    backgroundColor: 'transparent',
                    // paddingLeft: '0.2%',
                    paddingBottom:'3%'
                  }}
                >
                     <div className={classes.ServicesDescription}>
                      <div style={{position:'relative',height:'fit-content',alignSelf:'center'}}>
                      <h3>Unlocking Possibilities through Technology</h3>
                      <p>We are driven by a passion for technology and a commitment to transforming businesses through innovative solutions. Since our inception, we have been at the forefront of delivering exceptional software, web, and mobile services to clients worldwide. Our journey began with a vision to bridge the gap between cutting-edge technology and businesses, empowering them to thrive in the digital age.</p></div>
                    </div>
                </SwiperSlide>
            
            {ServicesData.map((item) => {
              return (
                <SwiperSlide
                  style={{
                    height: "auto",
                    backgroundColor: 'transparent',
                    paddingBottom:'3%',
                  }}
                >
                  <div key={item.id} className={classes.servicesCard}>
                    <div className={classes.serviceImage}><img src={item.image} alt='' style={{height:'100%',width:'auto'}}/></div>
                    <h3 style={{width:'90%',marginLeft:'5%'}}>{item.title}</h3>
                    <p style={{width:'90%',marginLeft:'5%',color: '#EB4138', marginBottom:'o'}}>{item.subTitle}</p>
                    <p style={{width:'90%',marginLeft:'5%',marginTop:'0'}}>{item.description}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          </div>
    </div>
  )
}

export default Services
