import React, { useState, useEffect } from 'react';
import img1 from '../../assets/productdesigner.png'

const AuthContext = React.createContext({
  selectedjob: {},
  setselectedJob:() => {},
  selectedTrend: {},
  setselectedTrend:() => {},

});

export const AuthContextProvider = (props) => {

  const [selectedTrend, setselectedTrend] = useState({
    date: "August 10,2023",
    title: "Web 3.0 Unlocking the future",
    subtitle:"Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology. This paradigm shift is driven by blockchain technology, introducing new levels of transparency, security, and user autonomy.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: ["3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ","\n","\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience."],
    subtitle2: "The Creative Process",
    description2: ["The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ","\n","\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process.","\n","\n \n* Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look.","\n","\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ","\n","\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters.","\n","\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character."],
    description: "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    image: img1,
  });


  const [selectedjob, setselectedJob] = useState({
    title: "",
    location:"",
    department: "",
    jobtype: "",
    dateposted: '',
    experience: "",
    workinghours: '',
    workingdays: "",
    lookingfor: [],
    willdoing: [],
    education: "",
    perks:[],
    deadline:'',
    categoryId:'',
    applyURL:'',
    linkedinURL:'',
  });


  const setselectedTrendhandler = (setselectedTrend1) => {
    setselectedTrend(setselectedTrend1)
  }

  const setselectedJobhandler = (selectedjob) => {
    setselectedJob(selectedjob)
  }

  const contextValue = {
    selectedjob: selectedjob,
    setselectedJob: setselectedJobhandler,
    selectedTrend: selectedTrend,
    setselectedTrend: setselectedTrendhandler,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;