import React, { Fragment, useContext, useState } from "react";
import classes from "./Career.module.css";
import video from "../../assets/HeroBackground.mp4";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Common/authcontext";
import { career, categories } from "../Constants/Data";

const Career = () => {
  const [selectedCategory, setselectedCategory] = useState(1);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  return (
    <div className={classes.career}>
      <div className={classes.career_con}>
        <div className={classes.CatchyHeadline}>
          <div className={classes.videoContainer}>
            <video className={classes.video} autoPlay loop muted playsInline>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={classes.taglineCont}>
            <div className={classes.Tagline}>
              <h2>Career Openings</h2>
            </div>
            <div className={classes.description}>
              <p>
                We’re always looking for creative, talented self-starters to
                join the family. Check out our open roles below and fill out an
                application.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.header}>
            <h2>WHY YOU SHOULD JOIN OUR AWESOME TEAM?</h2>
            <p>
              We are driven by a passion for technology and a commitment to
              transforming businesses through innovative solutions. We are a
              diverse team of skilled professionals dedicated to turning ideas
              into reality.
            </p>
          </div>
          <div className={classes.table}>
            <div className={classes.sidebar}>
              {categories.map((props) => {
                return (
                  <p
                    onClick={() => setselectedCategory(props.id)}
                    className={`${
                      selectedCategory === props.id
                        ? classes.selectedp
                        : classes.p
                    }`}
                  >
                    {props.category}
                  </p>
                );
              })}
              <div className={classes.mobilebtns}>
                {categories.map((props) => {
                  return (
                    <button
                      onClick={() => setselectedCategory(props.id)}
                      className={`${
                        selectedCategory === props.id
                          ? classes.selectedbtn
                          : classes.btn
                      }`}
                    >
                      {props.category}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={classes.rows}>
              {career.map((props) => {
                  return (
                    <>
                    {props.categoryId === selectedCategory && 
                      <Fragment>
                      <div className={classes.card}>
                        <h4>{props.title}</h4>
                        <div>
                          <div className={classes.carddesktoprow}>
                            <div className={classes.column}>
                              <p style={{ color: "#EB4138" }}>Location</p>
                              <p>{props.location}</p>
                            </div>
                            <div className={classes.column}>
                              <p style={{ color: "#EB4138" }}>Department</p>
                              <p>{props.department}</p>
                            </div>
                            <div className={classes.column}>
                              <p style={{ color: "#EB4138" }}>Job Type</p>
                              <p>{props.jobtype}</p>
                            </div>
                          </div>
                          <div className={classes.carddesktoprow}>
                            <div className={classes.column}>
                              <p style={{ color: "#EB4138" }}>Experience</p>
                              <p>{props.experience}</p>
                            </div>
                            <div className={classes.column}>
                              <p style={{ color: "#EB4138" }}>Deadline</p>
                              <p>{props.deadline}</p>
                            </div>
                            <div
                              onClick={() => {
                                authCtx.setselectedJob(props);
                                navigate(`/jobs/${props.title}/${props.id}`);
                              }}
                              className={classes.column}
                            >
                              <BsArrowRight
                                style={{
                                  width: "20%",
                                  height: "100%",
                                  marginLeft: "40%",
                                  color: "#EB4138",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.cardmobile}>
                        <div className={classes.cardrow1}>
                          <h4>{props.title}</h4>
                        </div>
                        <div className={classes.cardrow}>
                          <div className={classes.column}>
                            <p style={{ color: "#EB4138" }}>Location</p>
                            <p>{props.location}</p>
                          </div>
                          <div className={classes.column}>
                            <p style={{ color: "#EB4138" }}>Department</p>
                            <p>{props.department}</p>
                          </div>
                          <div className={classes.column}>
                            <p style={{ color: "#EB4138" }}>Job Type</p>
                            <p>{props.jobtype}</p>
                          </div>
                          <div className={classes.column}>
                            <p style={{ color: "#EB4138" }}>Experience</p>
                            <p>{props.experience}</p>
                          </div>
                          <div className={classes.column}>
                            <p style={{ color: "#EB4138" }}>Deadline</p>
                            <p>{props.deadline}</p>
                          </div>
                          <div
                            onClick={() => {
                              authCtx.setselectedJob(props);
                              navigate(`/jobs/${props.name}/${props.id} `);
                            }}
                            className={classes.column}
                          >
                            <BsArrowRight
                              style={{
                                width: "20%",
                                height: "100%",
                                marginLeft: "40%",
                                color: "#EB4138",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                    }</>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
