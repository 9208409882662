import React from 'react'
import BlogIntro from './Intro Section/BlogIntro'
import CompanyExpertiseAndExperience from '../About Us Page/Company\'s Expertise and Experience/CompanyExpertiseAndExperience'

const BlogPage = () => {
  return (
    <div style={{background:'#eeee'}}>
        <BlogIntro />
        <CompanyExpertiseAndExperience />
    </div>
  )
}

export default BlogPage