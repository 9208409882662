import React from 'react'
import classes from './Terms.module.css'
import termsimg from "../../assets/terms.png";

const terms = [
  {
    title: '1. Acceptance of Terms',
    text: 'Welcome to Nested Stacks ("we," "us," or "our"). By accessing or using our website ("Site") and any related services or software, you agree to comply with and be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, please do not use our Site.',
  },
  {
    title: '2. Changes to Terms',
    text: 'We may update or revise these Terms at any time without notice. Your continued use of the Site after any changes to these Terms signifies your acceptance of those changes. You are responsible for regularly reviewing these Terms to stay informed of updates.    ',
  },
  {
    title: '3. Use of Site    ',
    text: "You must be at least 18 years old to use this Site. You agree to use the Site only for lawful purposes and in a manner that does not violate any applicable laws or regulations. You may not interfere with the operation of the Site or use it in a way that harms or disrupts other users' access to the Site. ",
  },
  {
    title: '4. Privacy',
    text: 'Our Privacy Policy [Link to Privacy Policy] describes how we collect, use, and protect your personal information. By using our Site, you consent to our data practices as described in the Privacy Policy.',
  },
  {
    title: '5. Intellectual Property',
    text: 'All content on this Site, including text, graphics, logos, images, software, and other materials, is the property of Nested Stacks or its licensors and is protected by copyright and other intellectual property laws. You may not use, modify, reproduce, or distribute any content from the Site without our prior written consent.',
  },
  {
    title: '6. Disclaimer of Warranties',
    text: 'The information provided on this Site is for general informational purposes only. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the content. Your use of the Site and any information obtained from or through the Site is at your own risk. We disclaim all warranties, express or implied, including any warranties of merchantability, fitness for a particular purpose, and non-infringement.',
  },
  {
    title: '7. Limitation of Liability',
    text: 'To the fullest extent permitted by law, we are not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Site, even if we have been advised of the possibility of such damages. Our total liability to you for any claim related to the Site shall not exceed the amount you paid, if any, for any services provided through the Site.',
  },
  {
    title: '8. Indemnification',
    text: "You agree to indemnify, defend, and hold us harmless from any claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from your use of the Site, your violation of these Terms, or your infringement of any intellectual property or other rights of any third party.",
  },
  {
    title: '9. Termination',
    text: 'We reserve the right to terminate or suspend your access to the Site, without notice, for any reason, including a violation of these Terms.',
  },
  {
    title: '10. Governing Law and Jurisdiction',
    text: 'These Terms are governed by and construed in accordance with the laws of Lebanon. You agree that any legal action or proceeding arising out of or related to these Terms shall be filed exclusively in the state or federal courts located in Lebanon, and you consent to the personal jurisdiction of such courts.',
  },
  {
    title: '11. Contact Information',
    text: 'If you have any questions or concerns about these Terms, please contact us at info@nestedstacks.com.',
  },
]

const Terms = () => {
  return (
    <>
        <img src={termsimg} alt='' width='100%'/>
    <div className={classes.terms}>
      <div className={classes.content}>
          <div className={classes.title}>
            <h1>Terms of Use</h1>
            <p>Last Updated: 27/01/2021</p>
          </div>
          <div className={classes.text}>
              {terms.map((data)=>{
                return(
                  <div>
                    <h3>{data.title}</h3>
                    <p>{data.text}</p>
                  </div>
                )
              })}
              <h3>Thank you for using Nested Stacks!</h3>
          </div>
      </div>
    </div>
    </>
  )
}

export default Terms