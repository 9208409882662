import img from "../../assets/placeholders/1.jpg";
import aliharb from "../../assets/team/ali harb.png";
import deebmoe from "../../assets/team/Deeb Moe.png";
import housseinAlawieh from "../../assets/team/Hussen Alawieh.png";
import mhmdAlawieh from "../../assets/team/Mhmd Alawieh.png";
import naderMansour from "../../assets/team/Nader Mansour.png";
import mahdiRida from "../../assets/team/Mahdi Rida.png";
import amani from "../../assets/team/Amanii.png";
import newsss from "../../assets/news/development.jpg";



const categories = [
  { category: "Development", id: 1 },
  { category: "Engineering", id: 2 },
  { category: "Support", id: 3 },
  { category: "Design", id: 4 },
  { category: "Digital Marketing", id: 5 },
];

const career = [
  {
    id: 1,
    title: "Full-Stack Developer",
    location: "Beirut-Lebanon",
    department: "Development",
    jobtype: "Remote",
    dateposted: "2023-10-22",
    experience: "5 years",
    workinghours: "9 hours",
    workingdays: "5 days",
    lookingfor: [
      {
        id: 1,
        content:
          "We are in search of a 5+ years of experience passionate and skilled Full Stack Developer with expertise in React.js and .NET Core. The ideal candidate is someone who thrives in a collaborative environment, is dedicated to writing clean and efficient code, and has a strong sense of creativity combined with technical proficiency. You should be someone who keeps up-to-date with the latest industry trends, exhibits excellent problem-solving skills, and is eager to contribute to innovative projects.",
      },
    ],
    willdoing: [
      {
        id: 1,
        content:
          "As a Full Stack Developer at NestedStacks, you will be responsible for a diverse set of tasks, including:",
      },
      { id: 2, content: "Front-End Development:" },
      {
        id: 1,
        content:
          "Utilize React.js to develop intuitive and responsive user interfaces..",
      },
      { id: 2, content: "Experience with Java Script, HTML, CSS is a plus." },
      {
        id: 1,
        content:
          "Collaborate closely with UX/UI designers to implement visually appealing designs into functional web applications.",
      },
      { id: 2, content: "Experience with MUI, Bootsrap, Tailwind." },
      {
        id: 1,
        content: "Experience with graphic design applications such as Figma.",
      },
      { id: 2, content: "Experience with responsive and adaptive design." },
      {
        id: 1,
        content: "Understanding of SEO principles.",
      },
      { id: 2, content: "Understanding of key design principles." },
      {
        id: 1,
        content: "Understanding of server-side rendering.",
      },
      { id: 2, content: "" },
      {
        id: 1,
        content: "Back-End Development:",
      },
      { id: 2, content: "Experience Developing .NET Core Back-End Api's." },
      {
        id: 1,
        content:
          "Design and maintain server-side logic using .NET Core, ensuring high performance and  responsiveness.",
      },
      { id: 2, content: "Understanding of API Rest – Soap JSON and XML." },
      {
        id: 1,
        content:
          "Develop robust and efficient APIs for seamless communication between front-end and back-end systems.",
      },
      {
        id: 2,
        content:
          "Troubleshoot and debug issues across the entire stack to ensure the application's robustness and performance.",
      },
      {
        id: 1,
        content: "",
      },
      { id: 2, content: "Full Stack Development:" },
      {
        id: 1,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      { id: 2, content: "Nullam dictum ligula a gravida porta." },
      {
        id: 1,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      { id: 2, content: "Nullam dictum ligula a gravida porta." },
      {
        id: 1,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      { id: 2, content: "Nullam dictum ligula a gravida porta." },
      {
        id: 1,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      { id: 2, content: "Nullam dictum ligula a gravida porta." },
      {
        id: 1,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      { id: 2, content: "Nullam dictum ligula a gravida porta." },
    ],
    education:
      "Bachelor’s degree in Computer Science, Information Technology, or a related field (or equivalent work experience), Proven experience as a Full Stack Developer with expertise in React.js and .NET Core, Good written and spoken English, ability to communicate technical subject matter clearly and efficiently.",
    perks: [
      {
        id: 1,
        content: "Flexible work hours and the option for remote work.",
      },
      { id: 2, content: "Medical Health Insurence Plans." },
      {
        id: 1,
        content: "Competitive salary and performance-based incentives.",
      },
      {
        id: 2,
        content:
          "Opportunities for professional development and attending industry conferences.",
      },
      {
        id: 1,
        content:
          "Collaborative and inclusive company culture with team-building activities.",
      },
    ],
    deadline: "2024-01-20",
    categoryId: 1,
    applyURL: "",
    linkedinURL: "",
  },
];

const news = [
  {
    title: "The Future of Web Development",
    date: "August 10,202322",
    subtitle: "We're excited to share the latest updates on our transformative project, Bridging the Digital Divide. In today's blog post, we'll take you through the project's goals, achievements, and the positive impact it's having on communities.",
    description:
      "•	The Bridging the Digital Divide initiative aims to bring digital literacy and technology access to underserved communities. Recognizing the importance of digital skills in today's world, our organization is committed to narrowing the gap between those with access to technology and those without.",
    subtitle1: "Community Empowerment",
    description1: [
      "Through collaborative workshops and training sessions, we've empowered community members with essential digital skills. From basic computer literacy to online communication, individuals are now better equipped for success in the digital age."
     ],
    subtitle2: "Access to Technology",
    description2: [
      "We've successfully provided access to computers and the internet in community centers, enabling residents to explore educational resources, job opportunities, and connect with the broader world.",
    ],
    subtitle3: "Impact on Education",
    description3: [
      "The project has had a significant impact on education. Students now have the resources to research, learn, and complete assignments online. This newfound access is contributing to improved academic performance and future opportunities.",
    ],
    subtitle4: "Success Stories",
    description4: [
      "Meet Sarah, a high school student from one of the communities we serve. Before the project, Sarah had limited access to online educational materials. Now, she's using the provided resources to excel in her studies and pursue her dream of attending college.",
    ],
    subtitle5: "Future Steps",
    description5: [
      "While we celebrate the achievements of Bridging the Digital Divide, our journey continues. We're committed to expanding the project to more communities, fostering partnerships with local organizations, and staying at the forefront of technology to adapt our initiatives for maximum impact.      ",
      "\n",
      "\n Stay tuned for more updates on our ongoing projects and the incredible stories of change we witness every day.",
      "\n",
      "\n Thank you for being a part of our mission to make technology accessible to all!",
      "\n"
     ],
    image: newsss,
  },
  {
    title: "title2",
    subtitle:
      "Learn about the latest trends in mobile  and how they can impact your business.",
    description:
      "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: [
      "3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ",
      "\n",
      "\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience.",
    ],
    subtitle2: "The Creative Process",
    description2: [
      "The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ",
      "\n",
      "\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process.",
      "\n",
      "\n \n* Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look.",
      "\n",
      "\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ",
      "\n",
      "\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters.",
      "\n",
      "\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character.",
    ],

    date: "August 10,20231",
    image: img,
  },
  {
    title: "title3",
    subtitle:
      "Learn about the latest trends in mobile  and how they can impact your business.",
    description:
      "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: [
      "3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ",
      "\n",
      "\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience.",
    ],
    subtitle2: "The Creative Process",
    description2: [
      "The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ",
      "\n",
      "\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process.",
      "\n",
      "\n \n* Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look.",
      "\n",
      "\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ",
      "\n",
      "\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters.",
      "\n",
      "\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character.",
    ],
    date: "August 10,2022",
    image: img,
  },
  {
    title: "title7",
    subtitle:
      "Learn about the latest trends in mobile  and how they can impact your business.",
    description:
      "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: [
      "3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ",
      "\n",
      "\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience.",
    ],
    subtitle2: "The Creative Process",
    description2: [
      "The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ",
      "\n",
      "\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process.",
      "\n",
      "\n \n* Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look.",
      "\n",
      "\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ",
      "\n",
      "\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters.",
      "\n",
      "\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character.",
    ],
    date: "August 10,2024",
    image: img,
  },
  {
    title: "title11",
    subtitle:
      "Learn about the latest trends in mobile  and how they can impact your business.",
    description:
      "Web 3.0 marks a groundbreaking leap in the evolution of the internet, promising a decentralized future that transforms the way we engage with digital technology.",
    subtitle1: "Understanding 3D Character Modeling and Design",
    description1: [
      "3D character modeling is the process of creating digital representations of characters with depth and dimensionality. Unlike traditional 2D illustrations, 3D character modeling allows artists to develop characters that can be viewed from any angle, adding realism and depth to their appearance. The process involves manipulating digital vertices, edges, and faces to form a three-dimensional mesh that becomes the foundation of the character. ",
      "\n",
      "\n The design aspect of 3D character modeling is equally crucial. It encompasses conceptualizing the character's appearance, personality, and role within the narrative. Designers meticulously consider the character's features, clothing, accessories, and overall aesthetics to ensure they align seamlessly with the intended narrative and evoke the desired emotions from the audience.",
    ],
    subtitle2: "The Creative Process",
    description2: [
      "The journey of creating a compelling 3D character is a collaborative and iterative process. It typically involves the following stages: ",
      "\n",
      "\n *Concept Art:* The initial phase involves conceptualizing the character's appearance through sketches and digital concept art. This stage lays the foundation for the character's design and provides a roadmap for the modeling process.",
      "\n",
      "\n \n* Modeling: * Using specialized software, artists sculpt the character in a 3D environment, carefully refining the mesh to achieve the desired form and proportions. Attention to detail is critical, as small adjustments can significantly impact the character's overall look.",
      "\n",
      "\n * Texturing and Materials: * Once the 3D model is complete, artists apply textures and materials to the character's surface, adding color, patterns, and realistic details. This step brings the character to life visually, enhancing its believability. ",
      "\n",
      "\n * Rigging: * Rigging is the process of adding a digital skeleton to the character, allowing for realistic movement and animation. This step is vital for creating lifelike and expressive characters.",
      "\n",
      "\n * Animation: * With the character rigged, animators breathe life into it through movement and expressions, infusing personality and emotion into the character.",
    ],
    date: "August 10,2025",
    image: img,
  },
];

const team = [
  {
    name: "Ali Harb",
    jobtitle: "FOUNDER & CEO",
    image: aliharb,
  },
  {
    name: "Nader Mansour",
    jobtitle: "Development Director",
    image: naderMansour,
  },
  {
    name: "Amani Choujaa",
    jobtitle: "Art Director",
    image: amani,
  },
  {
    name: "Mahdi Rida",
    jobtitle: "Team Lead Mobile Development",
    image: mahdiRida,
  },
  {
    name: "Houssein Alawieh",
    jobtitle: "Senior Full-Stack Developer",
    image: housseinAlawieh,
  },
  {
    name: "Mohamad Alawieh",
    jobtitle: "Senior Full-Stack Developer",
    image: mhmdAlawieh,
  },
  {
    name: "Mohamad Deeb",
    jobtitle: "Junior Full-Stack Developer",
    image: deebmoe,
  },
];

export { categories, career, news, team };
