import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Footer.module.css";
import Logo from "../../../assets/Logo.png";
import facebook from "../../../assets/facebook.png";
import instagram from "../../../assets/instagram.png";
import linkedin from "../../../assets/linkedinwhite.png";
import { Alert, Snackbar, colors } from "@mui/material";
import { Input } from "@mui/joy";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'
import * as Scroll from 'react-scroll';
import emailjs from "@emailjs/browser";
import AuthContext from "../authcontext";

const Footer = () => {
  const [email, setEmail] = useState(false);
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorrr, setErrorrr] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const history = useNavigate();
  const scroller = Scroll.scroller;
  const authCtx = useContext(AuthContext)

  const scrollToProjects = () => {
    scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  const scrollToPortfolio = () => {
    scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };

  const goToHomeAndScrollProjects = async () => {
    await history('/');
    await scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  
  const goToHomeAndScrollPortfolio = async () => {
    await history('/');
    await scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };


  const handleSaveTime = () => {
    const now = new Date();
    setCurrentTime(now.toLocaleString()); // You can format this as desired
  };

  console.log(currentTime)

  const form = useRef();
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if ( email) {
      console.log("donnnneeee");

      setIsSubmit(true);
      const templateParams = {
        email: form.current.email.value,
        currentTime: currentTime, // Include the current time
      };

      emailjs
        .sendForm("service_ck4jm07", "template_lvu4t1o",  form.current, "XiTlzN1mqtZsVbs0n", templateParams)
        .then(
          (result) => {
            console.log(result.text);
            form.current.reset();

            setOpen(true);
          },
          (error) => {
            setErrorrr(true);
            console.log(error.text);
          }
        );
    } else {
      setFormErrors(Validation( email));
    }
  };
  console.log(email)
  useEffect(() => {
    console.log(formerrors);
    if (Object.keys(formerrors).length === 0 && isSubmit) {
      console.log(email);
    }
  }, [formerrors, email, isSubmit]);

  const Validation = () => {
    const errors = {};

    if (!email) {
      errors.email = "*Email is required and must include @!";
    }
    return errors;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setErrorrr(false);
  };
  
 const scrollToTop =() =>{
    scroll.scrollToTop();
  }
  return (
    <div className={classes.footer}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
      Subscribed Successfully!
      <br />
      Your email was sent successfully.
    </Alert>
  </Snackbar>
  <Snackbar autoHideDuration={6000} onClose={handleClose}>
    <Alert
      open={errorrr}
      onClose={handleClose}
      severity="error"
      sx={{ width: "100%" }}
    >
      An unexpected error occurred.Email is not sent!
      <br />
      Please try again.
    </Alert>
  </Snackbar>
      <div className={classes.footer_con}>
        <div className={classes.logomobile}>
              <img src={Logo} alt="" className={classes.logo_con} />
        </div>
        <div className={classes.topcontent}>
          {/* <div >
            </div> */}
          <div className={classes.routing}>
            <div className={classes.desc}>
              <img src={Logo} alt="" className={classes.logo_con} />
              <p>
                Stay in the loop with the latest updates and exclusive offers – subscribe to our newsletter and never miss a beat.
              </p>
              <form ref={form} onSubmit={SendEmailHandler}>
              <Input
              placeholder="Your Email"
              name="email"
              onChange={(e)=>setEmail(e.target.value)}
              style={{color: 'white', borderRadius:'10px', border:'1px solid #fff',background: 'transparent',padding:'5px', width:'80%'}}
                endDecorator={
                  <button type="submit" onClick={handleSaveTime} className={classes.btn}>SUBSCRIBE 
                  <span style={{ margin: "2px 20px 0 40px" }}>
                    <BsArrowRight />
                  </span></button>
                }
              />
               {!regex.test(email) ? (
                  <div className={classes.contact_option_error}>
                    {formerrors.email}
                  </div>
                ) : (
                  ""
                )}
                </form>
            </div>
            <div className={classes.options}>
            {location === '/' ? (
        <div style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2 onClick={scrollToPortfolio}>SERVICES</h2></div>
              
      ) : (
        <div style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2 onClick={goToHomeAndScrollPortfolio}>SERVICES</h2></div>
      )}
      {location === '/' ? (
        <div style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2 onClick={scrollToProjects}>PROJECTS</h2></div>
              
      ) : (
        <div style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2 onClick={goToHomeAndScrollProjects}>PROJECTS</h2></div>
      )}
              <div onClick={()=>history(`/news/${authCtx.selectedTrend.title}`)} style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2>NEWS</h2></div>
              <Link to='/aboutus' style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2>ABOUT</h2></Link>
              <Link to='/careers' style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><h2>CAREERS</h2></Link>
          <div className={classes.termsmobile}>
            <Link to='/policy' style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><p>Privacy Policy</p></Link>
            <Link to='/terms' style={{textDecoration:'underline',color:'#fff', cursor:'pointer'}}><p>Terms of Use</p></Link>
          </div>
            </div>
            <div className={classes.contact}>
              <h2>Contact Info</h2>
              <p>+961 70 916987</p>
              <p>info@nestedstacks.com</p>
              <p>Tayouneh Chiyah, Beirut Lebanon</p>
              <div className={classes.socials}>
                <Link to='https://www.facebook.com' target="_blank"  rel="noreferrer"><img src={facebook} alt="" width='45%'/></Link>
                <Link to='https://www.instagram.com' target="_blank"  rel="noreferrer"><img src={instagram} alt="" width='45%'/></Link>
                <Link to='https://www.linkedin.com' target="_blank"  rel="noreferrer"><img src={linkedin} alt="" width='45%'/></Link>
              </div>
            </div>
            <div className={classes.contactmobile}>
            <h2>Nested Stacks</h2>
              <p>Tayouneh Chiyah, Beirut Lebanon</p>
            <h2 style={{marginTop:'20%'}}>Contact</h2>
              <p>+961 70 916987</p>
              <p>info@nestedstacks.com</p>
              <div className={classes.socialsmobile}>
                <a href="https://www.facebook.com" target="_blank"  rel="noreferrer"><img src={facebook} alt="" width='45%'/></a>
                <a href="https://www.instagram.com" target="_blank"  rel="noreferrer"><img src={instagram} alt="" width='45%'/></a>
                <a href="https://www.linkedin.com" target="_blank"  rel="noreferrer"><img src={linkedin} alt="" width='45%' /></a>
              </div>
            </div>
          </div>
          <div className={classes.subscribe_mob}>
          <form ref={form} onSubmit={SendEmailHandler}>
              <Input
              placeholder="Your Email"
              name="email"
              onChange={(e)=>setEmail(e.target.value)}
              style={{color: 'white', borderRadius:'10px', border:'1px solid #fff',background: 'transparent',padding:'3px', width:'96%'}}
                endDecorator={
                  <button type="submit" onClick={handleSaveTime} className={classes.btn}>SUBSCRIBE 
                  <span style={{ margin: "2px 10px 0 15px" }}>
                    <BsArrowRight />
                  </span></button>
                }
              />
               {!regex.test(email) ? (
                  <div style={{color:'#fff'}} className={classes.contact_option_error}>
                    {formerrors.email}
                  </div>
                ) : (
                  ""
                )}
                </form>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomcontent}>
          <div className={classes.terms}>
            <Link to='/policy' style={{textDecoration:'underline',color:'#fff'}}><p>Privacy Policy</p></Link>
            <Link to='/terms' style={{textDecoration:'underline',color:'#fff'}}><p>Terms of Use</p></Link>
          </div>
          <div className={classes.copyright}>
            <p>&copy; Copyright 2021 All Rights Reserved by Nested Stacks.</p>
          </div>
          <div className={classes.back}>
            <ScrollLink onClick={scrollToTop} style={{textDecoration:'underline',color:'#fff'}}><p>Back to Top</p></ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
