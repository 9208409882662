import { useState } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import ScrollToTop from './Components/Common/ScrollToTop';
import Terms from './Components/Terms Page/Terms';
import Navbar from './Components/Common/Navbar Section/Navbar';
import Footer from './Components/Common/Footer Section/Footer';
import Contactus from './Components/Contact Us Page/Contactus';
import HomePage from './Components/Home Page/HomePage';
import Privacy from './Components/Privacy Page/Privacy';
import AboutUsPage from './Components/About Us Page/AboutUsPage';
import Sidebar from './Components/Common/Sidebar Section/Sidebar';
import Career from './Components/Career Page/Career';
import BlogPage from './Components/Blog Page';
import JobDetails from './Components/Job Details Page/JobDetails';


function App() {
  const [isOpen, setIsOpen] = useState(false);

 const toggle = () => {
   setIsOpen(!isOpen);
 };
  return (
    <div className="App">
      <Navbar toggle={toggle}/>
       <Sidebar isOpen={isOpen} toggle={toggle} />
      <div style={{transition: 'opacity 0.3s ease-in-out'}}>
        <Routes>
          <Route path='/' element={<ScrollToTop><HomePage/></ScrollToTop>} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path='/contactus' element={<ScrollToTop><Contactus /></ScrollToTop>} />
          <Route path='/terms' element={<ScrollToTop><Terms /></ScrollToTop>} />
          <Route path='/policy' element={<ScrollToTop><Privacy /></ScrollToTop>} />
          <Route path='/aboutus' element={<ScrollToTop><AboutUsPage /></ScrollToTop>} />
          <Route path='/news/:i' element={<ScrollToTop><BlogPage /></ScrollToTop>} />
          <Route path='/jobs/:i/:i' element={<ScrollToTop><JobDetails /></ScrollToTop>} />
          <Route path='/careers' element={<ScrollToTop><Career /></ScrollToTop>} />
        </Routes>
    </div>
  <Footer />
    </div>
  );
}

export default App;
