import React, { useContext } from "react";
import classes from "./JobDetails.module.css";
import video from "../../assets/HeroBackground.mp4";
import img from "../../assets/productdesigner.png";
import point from "../../assets/point.png";
import time from "../../assets/icons/timeicon.png";
import calender from "../../assets/icons/calender.png";
import hours from "../../assets/icons/hours.png";
import book from "../../assets/icons/book.png";
import days from "../../assets/icons/days.png";
import location from "../../assets/icons/location.png";
import { BsArrowRight } from "react-icons/bs";
import facebook from '../../assets/Facebookcolored.png'
import instagram from '../../assets/Instagramcolored.png'
import linkedin from '../../assets/Linkedincolored.png'
import AuthContext from '../Common/authcontext'
import { useNavigate } from "react-router-dom";

const JobDetails = () => {
  const authCtx = useContext(AuthContext)
  const nagivate = useNavigate()
  const handleApply = () => {
    const baselink = '';
    const shareLink = `${baselink}${encodeURIComponent(`${authCtx.selectedjob.applyURL}`)}`
      window.open(shareLink, '_blank');

  };
  const handleApplyOnLinkedIn = () => {
    const baselink = 'https://www.linkedin.com/sharing/share-offsite/?url=';
    const linkedInShareLink = `${baselink}${encodeURIComponent(`${authCtx.selectedjob.linkedinURL}`)}`
  
    window.open(linkedInShareLink, '_blank');
  };

  const pageUrl = window.location.href; // Get the current page URL

  // Generate an Instagram URL scheme with the caption
  const instagramShareUrl = `instagram://library?AssetPath=Images&InstagramCaption=${encodeURIComponent(pageUrl)}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`;
  // const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(pageUrl)}`;
  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(pageUrl)}`;
  // const whatsappShareUrl = `whatsapp://send?text=${encodeURIComponent(pageUrl)}`;

  return (
    <div className={classes.productdesign}>
      <div className={classes.productdesign_con}>
        <div className={classes.CatchyHeadline}>
        <div className={classes.videoContainer}>
          <video className={classes.video} autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
        <div className={classes.taglineCont}>
          <div className={classes.Tagline}>
            <h2>{authCtx.selectedjob.title}</h2>
          </div>
          <div className={classes.description}>
            <p>
            We'd be thrilled if you considered applying to join our team. Your talents would be a great asset to us!
            </p>
          </div>
          </div>
        </div>
        <div className={classes.bigContainer}>
        <img src={img} alt="" style={{ marginTop: "5%",width:'80%' }} />
        <div className={classes.content}>
          <div className={classes.description}>
            <div className={classes.title}>
              <h1>{authCtx.selectedjob.title}</h1>
            </div>
            <div className={classes.paragraph}>
              <h3>Who Are We Looking For</h3>
              {authCtx.selectedjob.lookingfor.map((data)=>{
                return(
                  
              <p>
                <span style={{ marginRight: "5px" }}>
                  <img src={point} alt="" className={classes.point} />
                </span>
                {data.content}
              </p>
                )
              })}
              
            </div>
            <div className={classes.paragraph}>
              <h3>What You Will Be Doing</h3>
              {authCtx.selectedjob.willdoing.map((data)=>{
                return(
                  
              <p>
                <span style={{ marginRight: "5px" }}>
                  <img src={point} alt="" className={classes.point} />
                </span>
                {data.content}
              </p>
                )
              })}
            </div>
            <div className={classes.paragraph}>
              <h3>Educational Requirements</h3>
              <p>
                {authCtx.selectedjob.education}
              </p>
            </div>
            <div className={classes.paragraph}>
              <h3>Perks & Benefits</h3>
              {authCtx.selectedjob.perks.map((data)=>{
                return(
                  
              <p>
                <span style={{ marginRight: "5px" }}>
                  <img src={point} alt="" className={classes.point} />
                </span>
                {data.content}
              </p>
                )
              })}
            </div>
            {/* <div style={{display:'flex' ,flexDirection:'row',columnGap:'1rem'}}>
            <button style={{ marginLeft: "5%" }}>
              Apply to Job{" "}
              <span style={{ padding: "0 5px" }}>
                <BsArrowRight />
              </span>
            </button>
            <button style={{ marginLeft: "5%" }}>
              Apply to Linkedin{" "}
              <span style={{ padding: "0 5px" }}>
                <BsArrowRight />
              </span>
            </button>
            </div> */}
          </div>
          <div className={classes.jobsummary_Con}>
            <div className={classes.jobsummary}>
              <h3>Job Summary</h3>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={location} alt="" />
                </div>
                <div>
                  <h4 style={{color:'#EB4138'}}>Location</h4>
                  <p>{authCtx.selectedjob.location}</p>
                </div>
              </div><div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={time} alt="" width='fit-content' height='fit-content'/>
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Department</h4>
                <p>{authCtx.selectedjob.department}</p>
                </div>
              </div>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={time} alt="" width='fit-content' height='fit-content'/>
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Job Type</h4>
                <p>{authCtx.selectedjob.jobtype}</p>
                </div>
              </div>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={calender} alt="" />
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Date posted</h4>
                <p>{authCtx.selectedjob.dateposted}</p>
                </div>
              </div>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={book} alt="" />
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Experience</h4>
                <p>{authCtx.selectedjob.experience}</p>
                </div>
              </div>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={hours} alt="" style={{margin:'auto '}} />
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Working Hours</h4>
                <p>{authCtx.selectedjob.workinghours}</p>
                </div>
              </div>
              <div className={classes.row}>
                <div style={{alignItems:'center', justifyItems: 'center', textAlign:'center',padding:'5%'}}>
                  {" "}
                  <img src={days} alt="" />
                </div>
                <div>
                <h4 style={{color:'#EB4138'}}>Working Days</h4>
                <p>{authCtx.selectedjob.workingdays}</p>
                </div>
              </div>
              <p style={{padding:'15% 10%', color:'#EB4138',textDecoration:'underline', textAlign:'end',fontFamily:'Raleway', fontSize:'calc(0.7rem + 0.3vw)', cursor:'pointer'}} onClick={()=>nagivate(`/careers`)}>View all jobs</p>
            </div>
            <div className={classes.btns}>
            {authCtx.selectedjob.applyURL && <button style={{ marginLeft: "5%" }}  className={classes.applybtn} onClick={handleApply}>
              Apply to Job{" "}
              <span style={{ padding: "0 5px" }}>
                <BsArrowRight />
              </span>
            </button>}
            {authCtx.selectedjob.linkedinURL && <button style={{ marginLeft: "5%" }} className={classes.linkedinbtn}  onClick={handleApplyOnLinkedIn}>
              Apply to Linkedin{" "}
              <span style={{ padding: "0 5px" }}>
                <BsArrowRight />
              </span>
            </button>}
            </div>
            <div className={classes.shareit}>
              <p >Share this:</p>
              <div style={{display:'flex', flexDirection:'row', columnGap:'1rem'}}>
                <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="" /></a>
                <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" /></a>
                <a href={linkedInShareUrl} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
