import React, { useState } from 'react'
import classes from './Process.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Process = () => {
    const [activeProcess, setactiveProcess] = useState('');
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
    const handleSlideChange = (swiper) => {
      setActiveSlideIndex(swiper.activeIndex);
    };
  
    const constantValue = activeSlideIndex + 1;

  return (
    <div style={{position:'relative',width:'100%'}}>
      <div className={classes.ProcessContainer}>
        <div className={classes.heading}>
            <div className={classes.header}>
                <h3>OUR PROCESS</h3>
            </div>
            <div className={classes.subTitle}>
              <p style={{width:'100%'}}>Turning Ideas into reality. Unlock innovation and drive growth with our tailored software solutions.
              </p>
              </div>
        </div>
        
        <div className={classes.contantContainer}>
        <div style={{position:'relative', width:'100%',height:'fit-content'}}>
            <div className={classes.contant} onMouseEnter={()=>setactiveProcess("Discovery")} onMouseLeave={()=>setactiveProcess("")}>
                <div className={classes.box}>
                    <div className={classes.box1} style={activeProcess ==="Discovery" ? {backgroundColor:'#EB4138',color:'#EB4138'} :{} }></div>
                    <div className={classes.line} onMouseOver={()=>setactiveProcess(activeProcess)}/>
                    </div>
                <h1 style={{margin:'0'}} className={activeProcess ==="Discovery" ? classes.hoveredTitle : classes.title}>Discovery</h1>
                <p className={activeProcess ==="Discovery" ? classes.hoveredDiscription : classes.discription}>We dive deep to understand your goals, challenges, and vision.</p>
            </div>
        </div>
        <div style={{position:'relative', width:'100%',height:'fit-content'}}>
            <div className={classes.contant} onMouseEnter={()=>setactiveProcess("Design")} onMouseLeave={()=>setactiveProcess("")}>
                <div className={classes.box} style={activeProcess ==="Design" ? {backgroundColor:'#EB4138'} :{} }/>
                <h1 style={{margin:'0'}} className={activeProcess ==="Design" ? classes.hoveredTitle : classes.title}>Design & Development</h1>
                <p className={activeProcess ==="Design" ? classes.hoveredDiscription : classes.discription}>Our expert team crafts tailored solutions that align with your business objectives.</p>
            </div>
        </div>
        <div style={{position:'relative', width:'100%',height:'fit-content'}}>
            <div className={classes.contant} onMouseEnter={()=>setactiveProcess("Assurance")} onMouseLeave={()=>setactiveProcess("")}>
                <div className={classes.box} style={activeProcess ==="Assurance" ? {backgroundColor:'#EB4138'} :{} }/>
                <h1 style={{margin:'0'}} className={activeProcess ==="Assurance" ? classes.hoveredTitle : classes.title}>Testing & Quality Assurance</h1>
                <p className={activeProcess ==="Assurance" ? classes.hoveredDiscription : classes.discription}>Rigorous testing ensures the highest quality performance and user experience.</p>
            </div>
        </div>
        <div style={{position:'relative', width:'100%',height:'fit-content'}}>
            <div className={classes.contant} onMouseEnter={()=>setactiveProcess("Deployment")} onMouseLeave={()=>setactiveProcess("")}>
                <div className={classes.box} style={activeProcess ==="Deployment" ? {backgroundColor:'#EB4138'} :{} }></div>
                <h1 style={{margin:'0'}} className={activeProcess ==="Deployment" ? classes.hoveredTitle : classes.title}>Deployment & Launch</h1>
                <p className={activeProcess ==="Deployment" ? classes.hoveredDiscription : classes.discription}>We seamlessly integrate the solution into your systems and support a successful launch.</p>
            </div>
        </div>
        </div>
        <div className={classes.swiper}>
          <Swiper
            onSlideChange={handleSlideChange}
            slidesPerView={2}
            centeredSlides={true}
            style={{
              margin: '5% 0 5% 0',
              zIndex: '1'
            }}
          >
                <SwiperSlide style={{height:'auto',backgroundColor:'transparent'}}>
                <div style={{position:'relative', width:'100%',height:'100%'}}>
                    <div className={classes.contant}>
                        <div className={classes.box}>
                        <div style={constantValue===1 ? {backgroundColor:'#EB4138',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}:{backgroundColor:'#1D212D',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}}/>
                            <div className={classes.line}/>
                        </div>
                        <h2 className={constantValue===1 ? classes.hoveredTitle : classes.title}>Discovery</h2>
                        <p className={constantValue===1 ? classes.hoveredDiscription : classes.discription}>We dive deep to understand your goals, challenges, and vision.</p>
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide style={{height:'auto',backgroundColor:'transparent'}}>
                    <div style={{position:'relative', width:'100%',height:'100%'}}>
                        <div className={classes.contant}>
                        <div className={classes.box}>
                        <div style={constantValue===2 ? {backgroundColor:'#EB4138',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}:{backgroundColor:'#1D212D',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}}/>
                                <div className={classes.line}/>
                            </div>
                            <h2 className={constantValue===2 ? classes.hoveredTitle : classes.title}>Design & Development</h2>
                            <p className={constantValue===2 ? classes.hoveredDiscription : classes.discription}>Our expert team crafts tailored solutions that align with your business objectives.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{height:'auto',backgroundColor:'transparent'}}>
                    <div style={{position:'relative', width:'100%',height:'100%'}}>
                        <div className={classes.contant}>
                        <div className={classes.box}>
                        <div style={constantValue===3 ? {backgroundColor:'#EB4138',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}:{backgroundColor:'#1D212D',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}}/>
                                <div className={classes.line}/>
                            </div>
                            <h2 className={constantValue===3 ? classes.hoveredTitle : classes.title}>Testing & Quality Assurance</h2>
                            <p className={constantValue===3 ? classes.hoveredDiscription : classes.discription}>Rigorous testing ensures the highest quality performance and user experience.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{height:'auto',backgroundColor:'transparent'}}>
                    <div style={{position:'relative', width:'100%',height:'100%'}}>
                        <div className={classes.contant}>
                        <div className={classes.box}>
                        <div style={constantValue===4 ? {backgroundColor:'#EB4138',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}:{backgroundColor:'#1D212D',zIndex:'10',borderRadius:'0.3em',position:'absolute',width:'100%',height:'100%',top:'0'}}/>
                                <div className={classes.line}/>
                            </div>
                            <h2 className={constantValue===4 ? classes.hoveredTitle : classes.title}>Deployment & Launch</h2>
                            <p className={constantValue===4 ? classes.hoveredDiscription : classes.discription}>We seamlessly integrate the solution into your systems and support a successful launch.</p>
                        </div>
                    </div>
                </SwiperSlide>
                
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Process
