import React, { useContext, useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import Logo from "../../../assets/Logo.png";
import Logoblack from "../../../assets/Logoblack.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import * as Scroll from 'react-scroll';
import AuthContext from "../authcontext";

const Navbar = ({ toggle }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const history = useNavigate();
  const scroller = Scroll.scroller;
  const authCtx = useContext(AuthContext)

  const scrollToProjects = () => {
    scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  const scrollToPortfolio = () => {
    scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };

  const goToHomeAndScrollProjects = async () => {
    await history('/');
    await scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  
  const goToHomeAndScrollPortfolio = async () => {
    await history('/');
    await scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };


  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY > 20;
      if (isTop !== isScrolled) {
        setIsScrolled(isTop);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);
  return (
    <div className={classes.navbar}>
      <div
        className={`${
          isScrolled ? classes.navbar_con : classes.navbar_con_before
        }`}
      >
        <div className={classes.content}>
          <div className={classes.logo_con}>
            {isScrolled ? (
              <Link to="/" activeClassName="active">
                <img src={Logoblack} alt="" />{" "}
              </Link>
            ) : (
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            )}
          </div>
          <div
            className={`${
              isScrolled ? classes.options_con_before : classes.options_con
            }`}
          >
      {location === '/' ? (
        <div className={`${isScrolled ? classes.links_before : classes.links}`}><p onClick={scrollToPortfolio}>SERVICES</p></div>
              
      ) : (
        <div className={`${isScrolled ? classes.links_before : classes.links}`}><p onClick={goToHomeAndScrollPortfolio}>SERVICES</p></div>
      )}
      {location === '/' ? (
        <div className={`${isScrolled ? classes.links_before : classes.links}`}><p onClick={scrollToProjects}>PROJECTS</p></div>
              
      ) : (
        <div className={`${isScrolled ? classes.links_before : classes.links}`}><p onClick={goToHomeAndScrollProjects}>PROJECTS</p></div>
      )}
            <div
              onClick={()=>history(`/news/${authCtx.selectedTrend.title}`)}
              className={`${isScrolled ? classes.links_before : classes.links}`}
            >
              <p>NEWS</p>
            </div>
            <Link
              to="/aboutus"
              className={`${isScrolled ? classes.links_before : classes.links}`}
            >
              <p>ABOUT</p>
            </Link>
            <Link
              to="/careers"
              className={`${isScrolled ? classes.links_before : classes.links}`}
            >
              <p>CAREERS</p>
            </Link>
            <Link
              to="/contactus"
              style={{ textDecoration: "none", width: "fit-content" }}
            >
              <button style={{ marginLeft: "5%" }}>
                GET IN TOUCH{" "}
                <span style={{ padding: "4px 0px 0 10px" }}>
                  {/* <BsArrowRight /> */}
                </span>
              </button>
            </Link>
          </div>
          <div className={classes.mobileicon}>
            <FaBarsStaggered
              style={{
                width: "30px",
                height: "30px",
                rotate: "180deg",
                margin: "5% 0",
              }}
              onClick={toggle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
