import React, { useContext } from "react";
import classes from "./CompanyExpertiseAndExperience.module.css";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

// Import Swiper styles
import "swiper/css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Common/authcontext";
import { news } from "../../Constants/Data";

const CompanyExpertiseAndExperience = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  return (
    <div className={classes.expertise}>
      <div className={classes.expertise_con}>
        <div className={classes.headerCont}>
          <div className={classes.header}>
            <h3>STAY UP TO DATE</h3>
            <p>Stay on the know to our newest blogs and insights</p>
          </div>
        </div>
          <div className={classes.mobileBG} />
        <div className={classes.swiper}>

          <Swiper
            style={{ height: "100%" }}
            spaceBetween={30}
            pagination={true}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                centeredSlides: true,
                spaceBetween: 10,
              },
              651: {
                slidesPerView: 2,
              },
              951: {
                slidesPerView: 3,
              },
              1250: {
                slidesPerView: 4,
              },
            }}
          >
          {news.map((data) => {
            return (
              <SwiperSlide
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  flexDirection: "column",
                  height: "auto",
                  paddingBottom:'3em'
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    background: "#fff",
                    borderRadius: "20px",
                    height: "fit-content",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      alignSelf: "center",
                      objectFit: "cover",
                      padding: "5% 5% 0 5%",
                      margin: " 0 auto",
                     
                    }}
                    className={classes.image_height}
                  >
                    <img
                      src={data.image}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>

                  <div className={classes.cardtext}>
                    <p>{data.title}</p>
                  </div>
                  <div className={classes.btncon}>
                    <p style={{ color: "#EB4138",fontSize: "calc(0.4rem + 0.5vw)", }}>{data.date}</p>
                    <button
                      className={classes.PrimaryCTAButton}
                      onClick={() => {
                        authCtx.setselectedTrend(data);
                        navigate(`/news/${data.title}`);
                      }}
                    >
                      <p style={{ lineHeight: "0" }}>READ MORE</p>{" "}
                      <TrendingFlatIcon
                        style={{
                          height: "1em",
                          position: "relative",
                          alignSelf: "center",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CompanyExpertiseAndExperience;
