import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Contact&Blogs.module.css";
import TextField from "../../Common/Text Field/TextField";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Alert, Snackbar } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Common/authcontext";

// Import Swiper styles
import "swiper/css";
import { news } from "../../Constants/Data";

const ContactAndBlogs = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorrr, setErrorrr] = useState(false);

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneno = /^\d{7,16}$/;
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if (name && phone && email && message) {
      console.log("doneeee");

      setIsSubmit(true);

      emailjs
        .send(
          "service_ck4jm07",
          "template_b1dwf66",
          formData,
          "XiTlzN1mqtZsVbs0n"
        )
        .then((result) => {
          console.log(result.text);
          setFormData({ name: "", phone: "", email: "", message: "" });
          form.resetFields();
        })
        .catch((error) => {
          console.log(error.text);
        });
    } else {
      setFormErrors(Validation(name, phone, email, message));
    }
  };
  useEffect(() => {
    console.log(formerrors);
    if (Object.keys(formerrors).length === 0 && isSubmit) {
      console.log(name, phone, email, message);
    }
  }, [formerrors,name, phone, email, message,isSubmit]);

  const Validation = () => {
    const errors = {};

    if (!name) {
      errors.name = "*Name is required!";
    }
    if (!email) {
      errors.email = "*Email is required and must include @!";
    }
    if (!phone) {
      errors.phone = "*Phone Number is requierd!";
    }
    if (!message) {
      errors.message = "*Message is requierd!";
    }
    return errors;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setErrorrr(false);
  };
  return (
    <div className={classes.bigContainer}>
      <div className={classes.heading}>
        <div className={classes.header}>
          <h3>STAY UP TO DATE</h3>
        </div>
        <div className={classes.subTitle}>
          <p style={{ width: "100%" }}>
            Stay on the know to our newest blogs and insights
          </p>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Email Delivered!
          <br />
          Your email was sent successfully.
        </Alert>
      </Snackbar>
      <Snackbar autoHideDuration={6000} onClose={handleClose}>
        <Alert
          open={errorrr}
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          An unexpected error occurred.Email is not sent!
          <br />
          Please try again.
        </Alert>
      </Snackbar>
      <div className={classes.contactUsCard}>
        <div className={classes.contents}>
          <h2>Ready to Innovate?</h2>
          <h4>Let's Turn Your Ideas into Reality</h4>
          <form ref={form} onSubmit={SendEmailHandler}>
            <TextField
              label="Name*"
              name="name"
              placeholder="Your Name"
              variant="standard"
              rows={1}
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
                setName(e.target.value);
              }}
              inputProps={{ style: { color: "white" } }}
            />
            {name.length <= 0 ? (
              <div className={classes.contact_option_error}>
                {formerrors.name}
              </div>
            ) : (
              ""
            )}
            <TextField
              label="E-mail Address*"
              placeholder="Your E-mail Address"
              variant="standard"
              rows={1}
              name="email"
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
                setEmail(e.target.value);
              }}
              inputProps={{ style: { color: "white" } }}
            />
            {!regex.test(email) ? (
              <div className={classes.contact_option_error}>
                {formerrors.email}
              </div>
            ) : (
              ""
            )}
            <TextField
              label="Phone Number*"
              placeholder="Phone Number"
              variant="standard"
              rows={1}
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
                setPhone(e.target.value);
              }}
              inputProps={{ style: { color: "white" } }}
            />
            {!phoneno.test(phone) ? (
              <div
                className={classes.contact_option_error}
                style={{ marginBottom: "2em" }}
              >
                {formerrors.phone}
              </div>
            ) : (
              ""
            )}
            <TextField
              id="message"
              label="Message*"
              placeholder="Write a message"
              variant="standard"
              rows={3}
              value={formData.message}
              onChange={(e) => {
                setFormData({ ...formData, message: e.target.value });
                setMessage(e.target.value);
              }}
              name="message"
              inputProps={{ style: { color: "white" } }}
            />
            {message.length <= 0 ? (
              <div className={classes.contact_option_error}>
                {formerrors.message}
              </div>
            ) : (
              ""
            )}
            <button onClick={SendEmailHandler}>
              <p>GET IN TOUCH</p>{" "}
              <TrendingFlatIcon
                style={{
                  height: "1em",
                  position: "relative",
                  alignSelf: "center",
                }}
              />
            </button>
          </form>
        </div>
      </div>
      <div className={classes.swiper}>
        <Swiper
          centeredSlides={true}
          style={{ height: "100%" }}
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              centeredSlides: true,
              spaceBetween: 10,
            },

            651: {
              slidesPerView: 2.5,
              centeredSlides: true,
              spaceBetween: 30,
            },
            951: {
              slidesPerView: 2,
              centeredSlides: false,
              spaceBetween: 30,
            },
          }}
        >
          {news.map((data) => {
            return (
              <SwiperSlide
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  flexDirection: "column",
                  height: "auto",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    background: "#fff",
                    borderRadius: "20px",
                    height: "fit-content",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "90%",
                      alignSelf: "center",
                      objectFit: "cover",
                      padding: "5% 5% 0 5%",
                      margin: " 0 auto",
                     
                    }}
                    className={classes.image_height}
                  >
                    <img
                      src={data.image}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>

                  <div className={classes.cardtext}>
                    <p>{data.title}</p>
                  </div>
                  <div className={classes.btncon}>
                    <p style={{ color: "#EB4138",fontSize: "calc(0.4rem + 0.5vw)", }}>{data.date}</p>
                    <button
                      className={classes.PrimaryCTAButton}
                      onClick={() => {
                        authCtx.setselectedTrend(data);
                        navigate(`/news/${data.title}`);
                      }}
                    >
                      <p style={{ lineHeight: "0" }}>READ MORE</p>{" "}
                      <TrendingFlatIcon
                        style={{
                          height: "1em",
                          position: "relative",
                          alignSelf: "center",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className={classes.contactUsCardMob}>
        <div className={classes.contents}>
          <h2>Ready to Innovate?</h2>
          <h4>Let's Turn Your Ideas into Reality</h4>
          <form ref={form} onSubmit={SendEmailHandler}>
            <TextField
              id="name"
              label="Name*"
              name="name"
              placeholder="Your Name"
              variant="standard"
              rows={1}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ style: { color: "white" } }}
            />
            {name.length <= 0 ? (
              <div className={classes.contact_option_error}>
                {formerrors.name}
              </div>
            ) : (
              ""
            )}
            <TextField
              label="E-mail Address*"
              placeholder="Your E-mail Address"
              variant="standard"
              rows={1}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ style: { color: "white" } }}
            />
            {!regex.test(email) ? (
              <div className={classes.contact_option_error}>
                {formerrors.email}
              </div>
            ) : (
              ""
            )}
            <TextField
              label="Phone Number*"
              placeholder="Phone Number"
              variant="standard"
              rows={1}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              inputProps={{ style: { color: "white" } }}
            />
            {!phoneno.test(phone) ? (
              <div className={classes.contact_option_error}>
                {formerrors.phone}
              </div>
            ) : (
              ""
            )}
            <TextField
              id="message"
              label="Message*"
              placeholder="Write a message"
              variant="standard"
              rows={3}
              name="message"
              inputProps={{ style: { color: "white" } }}
            />
            <button>
              <p>GET IN TOUCH</p>{" "}
              <TrendingFlatIcon
                style={{
                  height: "1em",
                  position: "relative",
                  alignSelf: "center",
                }}
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactAndBlogs;
