import React, { useEffect, useState } from 'react'
import classes from './Portfolio.module.css'
import image from '../../../assets/Service.png'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const PortdolioData = [
  { id: '01.', image:image, title:'E-Commerce Platform', description: "Built a scalable e-commerce platform, resulting in a 30% increase in online sales." },
  { id: '02.', image:image, title:'Mobile App Redesign', description: "Transformed a mobile app's user interface, leading to a 40% rise in user engagement." },
  { id: '03.', image:image, title:'Custom CRM Solution', description: "Developed a tailored CRM system streamlining processes and improving customer relationships." },
  { id: '04.', image:image, title:'E-Commerce Platform', description: "Built a scalable e-commerce platform, resulting in a 30% increase in online sales." },
  { id: '05.', image:image, title:'E-Commerce Platform', description: "Built a scalable e-commerce platform, resulting in a 30% increase in online sales." },
  { id: '06.', image:image, title:'Mobile App Redesign', description: "Transformed a mobile app's user interface, leading to a 40% rise in user engagement." },
]

const Portfolio = () => {
  const [displayCount, setDisplayCount] = useState(4);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 650) {
        setDisplayCount(2);
        setIsMobile(true);
      } else {
        setDisplayCount(4);
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{position:'relative',width:'100%',backgroundColor:'#0F121B'}} id='projects'>
      <div className={classes.PortfolioContainer}>
        <div className={classes.heading}>
            <div className={classes.header}>
                <h3 >FEATURED PROJECTS</h3>
            </div>
            <div className={classes.subTitle}>
              <p style={{width:'100%'}}>See our success Stories. Unlock innovation and drive growth with our tailored software solutions.
              </p>
              </div>
        </div>
        <div className={classes.contantContainer}>
          {PortdolioData.slice(0, displayCount).map((item) => (
            <div className={classes.PortfolioCard}>
                  <div className={classes.contant}>
                    <div style={{width:'100%',textAlign:'start', color: '#EB4138'}}>
                      <p style={{borderBottom:'solid 1px',width:'fit-content'}}>{item.id}</p>
                    </div>
                    <div style={{width:'100%',textAlign:'start', color: '#EB4138'}}>
                      <p>{item.title}</p>
                    </div>
                    <div style={{width:'90%',textAlign:'start', marginTop:'6%'}}>
                      <img src={item.image} alt='' style={{width:'90%'}}/>
                    </div>
                    <div style={{width:'100%',textAlign:'start', color: '#F5F5F5'}}>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
      ))}
        </div>
            
        {displayCount < PortdolioData.length && (
        <button  onClick={()=>setDisplayCount(PortdolioData.length)}><p>SHOW MORE</p> <TrendingFlatIcon style={{height:'1em',position:'relative',alignSelf:'center'}}/></button>
        )}
        {displayCount === PortdolioData.length && (
        <button  onClick={()=>{if(isMobile){setDisplayCount(2)}else{setDisplayCount(4)}}}><p>SHOW LESS</p> <TrendingFlatIcon style={{height:'1em',position:'relative',alignSelf:'center'}}/></button>
        )}
      </div>
    </div>
  )
}

export default Portfolio
