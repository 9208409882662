import React, { useEffect, useState } from "react";
import video from "../../../assets/HeroBackground.mp4";
import img1 from "../../../assets/aboutus/choose-your-colors.svg";
import img2 from "../../../assets/aboutus/user-interface.svg";
import img3 from "../../../assets/aboutus/web-designer-setting-up-web-layout.svg";
import classes from "./BriefCompanyIntro.module.css";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";


const array = [
  {
    key: 1,
    title: "Our mission:",
    description: " is to empower businesses with transformative technology solutions that drive growth, enhance efficiency, and unlock new opportunities. We strive to be a trusted partner for businesses, guiding them through their digital transformation journey and helping them stay ahead in an ever-evolving landscape.",
    image: img1,
  },
  {
    key: 2,
    title: "Extensive Experience:",
    description: " With years of experience in the industry, we have deep understanding of business needs and the latest technological trends. Client-Centric Approach:We prioritize understanding your unique requirements and delivering solutions that align with your goals.",
    image: img2,
  },
  {
    key: 3,
    title: "Dedicated Team:",
    description:
      " Our passionate team of experts works tirelessly to ensure the success of your projects. Quality Assurance: Our commitment to delivering high-quality solutions ensures your satisfaction and business success.",
    image: img3,
  },
];

const BriefCompanyIntro = () => {
  const [imageSrc, setImageSrc] = useState(video);
  const [opacity, setopacity] = useState("seo");

  //for mobile swiper
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  const constantValue = activeSlideIndex + 1;
  useEffect(() => {
    array.forEach((item) => {
      if (item.key === constantValue) {
        setContent(item.description);
        setTitle(item.title)
      }
    });
  }, [constantValue]);


  return (
    <div className={classes.briefintro}>
      <div className={classes.briefintro_con}>
        <div className={classes.CatchyHeadline}>
        <div className={classes.videoContainer}>
          <video className={classes.video} autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
        <div className={classes.taglineCont}>
          <div className={classes.Tagline}>
            <h2>Why Choose Us?</h2>
          </div>
          <div className={classes.description}>
            <p>
              Nested Stacks is a leading software development company committed
              to delivering cutting-edge solutions that drive business success.
            </p>
          </div>
        </div>
        </div>
        <div className={classes.devscroll_con}>
          <div>
            <Controller>
              <Scene duration="200%" triggerHook="onLeave" pin>
                {(progress) => {
                  if (progress < 0.10) {
                    setImageSrc(img1);
                    setopacity("seo");
                  }
                  if (progress > 0.15 && progress < 0.40) {
                    setImageSrc(img2);
                    setopacity("metaverse");
                  }
                  if (progress > 0.40) {
                    setImageSrc(img3);
                    setopacity("employee");
                  }

                  return (
                    <section className={classes.testt}>
                      <span className={classes.imgCont}>
                        <img
                          className="image"
                          src={imageSrc}
                          alt=""
                          width='80%'
                          //  style={{transform: `rotate(${degree}deg)`}}
                        />
                      </span>
                    </section>
                  );
                }}
              </Scene>
            </Controller>
          </div>
          <div>
            <Controller>
              <Scene triggerHook="onLeave" duration="200%" pin>
                <Timeline>
                  <Tween from={{ y: "25%" }} to={{ y: "-43%" }}>
                    <section className={classes.panel}>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "seo" ? { opacity: 1 } : { opacity: 0.5 }
                        }
                      >
                      <p className={classes.description}>
                      <span style={{ fontWeight: 600 }}>
                        Our mission
                        </span> is to empower businesses with
                        transformative technology solutions that drive growth,
                        enhance efficiency, and unlock new opportunities. We
                        strive to be a trusted partner for businesses, guiding
                        them through their digital transformation journey and
                        helping them stay ahead in an ever-evolving landscape.
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "metaverse"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <p className={classes.description}>
                        {" "}
                        <span style={{ fontWeight: 600 }}>
                          Extensive Experience:
                        </span>{" "}
                        With years of experience in the industry, we have a
                        deep understanding of business needs and the latest
                        technological trends.{" "}
                        <span style={{ fontWeight: 600 }}>
                          <br/>Client-Centric Approach:
                        </span>{" "}
                        We prioritize understanding your unique requirements
                        and delivering solutions that align with your goals.
                      </p>
                      </span>
                      <span
                        className={classes.panelspan}
                        style={
                          opacity === "employee"
                            ? { opacity: 1 }
                            : { opacity: 0.5 }
                        }
                      >
                      <p className={classes.description}>
                        <span style={{ fontWeight: 600 }}>
                          Dedicated Team:
                        </span>{" "}
                        Our passionate team of experts works tirelessly to
                        ensure the success of your projects.{" "}
                        <span style={{ fontWeight: 600 }}>
                        <br/>Quality Assurance:
                        </span>{" "}
                        Our commitment to delivering high-quality solutions
                        ensures your satisfaction and business success.
                      </p>
                      </span>
                    </section>
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
          </div>
        </div>
      </div>
      <div className={classes.mobile}>
        <div>
          <Swiper className="mySwiper" pagination={true} modules={[Pagination]} onSlideChange={handleSlideChange}>
            {array.map((data) => {
              return (
                <SwiperSlide style={{background: 'transparent', height: '35vh',paddingBottom:'10%' }}>
                  <div style={{ position: "relative",width: 'fit-content', background: 'transparent' }}>
                    <img
                      src={data.image}
                      alt=""
                      width="80%"
                      style={{ justifySelf: "center", margin: "auto", background: 'transparent' }}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={classes.mobiletext}>
          <h3 style={{position:'relative',fontWeight: 600, paddingLeft:'10%'}}>{title}</h3>
          <p className={classes.description}>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default BriefCompanyIntro;
