import classes from './Sidebar.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/Logo.png'
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { BsArrowRight } from "react-icons/bs";
import * as Scroll from 'react-scroll';
import AuthContext from '../authcontext';


export default function Sidebar({toggle, isOpen}) {

  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const history = useNavigate();
  const scroller = Scroll.scroller;
    const authCtx = React.useContext(AuthContext)
  const list = (anchor) => {

  const scrollToProjects = () => {
    scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  const scrollToPortfolio = () => {
    scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };

  const goToHomeAndScrollProjects = async () => {
    await history('/');
    await scroller.scrollTo('projects', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
  
  const goToHomeAndScrollPortfolio = async () => {
    await history('/');
    await scroller.scrollTo('portfolio', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: 50
    });
  };
    
    return(
    <Box
      sx={{ width:  '100%' }}
      role="presentation"
      onClick={toggle}
      onKeyDown={toggle}
      className={classes.container}
    >
      <List>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText style={{
                textAlign: 'center'
              }}>
              <Link  to='/' onClick={toggle}>
                <img src={Logo} alt='Logo' className={classes.logo}/>
                     </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </List>
          <List>
              {location === '/' ? <Link  to='/' style={{textDecoration: 'none', color:'white'}} onClick={()=>toggle() & scrollToPortfolio()}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              SERVICES
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </Link>:
                     <Link  to='/' style={{textDecoration: 'none', color:'white'}} onClick={()=>toggle() & goToHomeAndScrollPortfolio()}>
                     <ListItem  disablePadding>
                       <ListItemButton>
                         <ListItemText className={classes.text}>
                         SERVICES
                         </ListItemText>
                       </ListItemButton>
                     </ListItem>
                                </Link>}
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              {location === '/' ? <Link to='/' style={{textDecoration: 'none', color:'white'}} onClick={()=>toggle() & scrollToProjects()}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              PROJECTS
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </Link>:
                     <Link to='/' style={{textDecoration: 'none', color:'white'}} onClick={()=>toggle() & goToHomeAndScrollProjects()}>
                     <ListItem  disablePadding>
                       <ListItemButton>
                         <ListItemText className={classes.text}>
                         PROJECTS
                         </ListItemText>
                       </ListItemButton>
                     </ListItem>
                                </Link>}
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <div style={{textDecoration: 'none', color:'white'}} onClick={()=>toggle()& history(`/news/${authCtx.selectedTrend.title}`)}>
            <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              NEWS
              </ListItemText>
            </ListItemButton>
          </ListItem>
                     </div>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/aboutus' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              ABOUT
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/careers' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
              CAREERS
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
          <Divider color='white' width='70%' style={{justifySelf: 'center', alignSelf:'center', marginLeft:'15%'}}/>
              <Link  to='/contactus' style={{textDecoration: 'none', color:'white'}} onClick={toggle}>
          <ListItem  disablePadding>
            <ListItemButton>
              <ListItemText className={classes.text}>
                <button><span></span>GET IN TOUCH<span style={{ padding: "4px 0px 0 10px" }}>
                  <BsArrowRight />
                </span></button>
              
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  )
            };

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={toggle}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}