import React from 'react'
import classes from './Testimonials.module.css'
import Rating from "@mui/material/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { HiArrowLongRight,HiArrowLongLeft } from 'react-icons/hi2';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const array = [
    {
        clientsName: "DR.Ali Hotiet",
        clientsPosition: "Owner at Dentail Beauty clinic",
        description: "Nested Stacks was Fantastic. Their expertise and determination to elevate our clinic was unbelievable.",
        rate: 5,
    },
    {
        clientsName: "Rabih Abboud",
        clientsPosition: "Owner at Aboud Trading Inc.",
        description: "From concept to execution, Nested Stacks guided us through every step. Their solutions have significantly enhanced our operations and customer experience.",
        rate: 5,
    },
    {
        clientsName: "ENG.Mike Kfoury",
        clientsPosition: "CEO at Techno Geek",
        description: "Working with Nested Stacks was a game-changer. Their attention to detail and technical expertise brought our project to a whole new level.",
        rate: 4,
    },
  ];

const Testimonials = () => {

    const styles = {
        style1: {
          position: "absolute",
          bottom:'0',
          right: "35.7%",
          width: "fit-content",
          color: "black",
          zIndex: 40,
          userSelect: 'none',
          transition: 'background-color 0.5s ease, color 1s ease',
        },
        style2: {
          position: "absolute",
          bottom:'0',
          right: "61.3%",
          width: "fit-content",
          color: "black",
          zIndex: 40,
          userSelect: 'none',
          transition: 'background-color 0.5s ease, color 1s ease',
        },
      };

  return (
    <div style={{position:'relative',width:'100%'}}>
      <div className={classes.TestimonialsContainer}>
        <div className={classes.heading}>
            <div className={classes.header}>
                <h3 >WHAT OUR CLIENTS SAY</h3>
            </div>
            <div className={classes.subTitle}>
              <p style={{width:'100%'}}>Exceeding ecpectations everytime.
              </p>
              </div>
        </div>
        <div className={classes.swiper}>
          <Swiper
            spaceBetween={50}
            autoHeight={false}
            effect={"fade"}
            navigation={{
              nextEl: ".button-next-slide",
              prevEl: ".button-prev-slide",
            }}
            modules={[Navigation]}
            // style={{
            //   margin: '5% 0 5% 0',
            //   zIndex: '1'
            // }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              },
            
          951: {
            slidesPerView: 2,
          },
        }}
          >
            {array.map((props) => {
              return (
                <SwiperSlide
                  style={{
                    height: "auto",
                    backgroundColor: 'transparent',
                    paddingBottom:'3em'
                  }}
                >
                  <div className={classes.clientsCard}>
                    <div className={classes.cardContant} >
                        <p className={classes.description}>"{props.description}"</p>
                        <div style={{position:'relative', width:'100%', display:'flex',flexDirection:'row'}}>
                            <div className={classes.name}>
                               <h4 style={{margin:'0'}}>{props.clientsName}</h4>
                               <p >{props.clientsPosition}</p> 
                            </div>
                            <div style={{position:'relative', width:'100%',display:'block',textAlign:'end'}}>
                            <Rating
                            style={{
                                position:'relative',
                                width:'fit-content',
                                color: "#EB4138",
                            }}
                            name="book-rate"
                         value={props.rate}
                        /></div>
                        </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={classes.arrowDesktop}>
            <div className="button-prev-slide" style={styles.style2}>
            <div className={classes.testttt}> <HiArrowLongLeft style={{width:'70%',height:'100%'}}/></div>
            </div>
            <div className="button-next-slide" style={styles.style1}>
              <div className={classes.testttt}> <HiArrowLongRight style={{width:'70%',height:'100%'}}/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
