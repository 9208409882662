import React from 'react'
import Hero from './Hero Section/Hero'
import Services from './Services Section/Services'
import Portfolio from './Portfolio Section/Portfolio'
import Testimonials from './Testimonials Section/Testimonials'
import Process from './Process Section/Process'
import * as Scroll from 'react-scroll';
import ContactAndBlogs from './Contact & Blogs Section/Contact&Blogs'

const HomePage = () => {
  const Element = Scroll.Element;
  const Element1 = Scroll.Element;
  return (
    <div style={{width:'100%', position:'relative',background: '#EEEEEE'}}>
      <Hero/>
      <Element1 name='portfolio'>
      <Services/>
      </Element1>
      <Element name='projects'>
      <Portfolio />
      </Element>
      <Testimonials/>
      <Process/>
      <ContactAndBlogs />
    </div>
  )
}

export default HomePage
