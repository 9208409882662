import React from "react";
import classes from "./BlogIntro.module.css";
import video from "../../../assets/HeroBackground.mp4";
import FormatText from "../../Common/FormatText";
import AuthContext from "../../Common/authcontext";

const BlogIntro = () => {
  const authCtx = React.useContext(AuthContext);

  return (
    <div className={classes.briefintro}>
      <div className={classes.briefintro_con}>
        <div className={classes.CatchyHeadline}>
        <div className={classes.videoContainer}>
          <video className={classes.video} autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
          <div className={classes.Tagline}>
            <h2>{authCtx.selectedTrend.title}</h2>
          </div>
          <div className={classes.description}>
            <p>{authCtx.selectedTrend.subtitle}</p>
          </div>
        </div>
    <div className={classes.blogintro_container}>
      <div className={classes.header}>
        {/* <h1>
        {authCtx.selectedTrend.title}
        </h1>
        <p style={{width:'80%',marginLeft:'10%'}}>
          {authCtx.selectedTrend.subtitle}
        </p> */}
        <img src={authCtx.selectedTrend.image} alt="" className={classes.image}/>
      </div>
      <div className={classes.body}>
      {authCtx?.selectedTrend.subtitle1 &&<h2>{authCtx?.selectedTrend.subtitle1}</h2>}
        {authCtx?.selectedTrend.subtitle1 &&<p><FormatText textArray={authCtx?.selectedTrend.description1}/></p>}
        {authCtx?.selectedTrend.subtitle2 &&<h2>{authCtx?.selectedTrend.subtitle2}</h2>}
        {authCtx?.selectedTrend.subtitle2 &&<p><FormatText textArray={authCtx?.selectedTrend.description2}/></p>}
        {authCtx?.selectedTrend.subtitle3 &&<h2>{authCtx?.selectedTrend.subtitle3}</h2>}
        {authCtx?.selectedTrend.subtitle3 &&<p><FormatText textArray={authCtx?.selectedTrend.description3}/></p>}
        {authCtx?.selectedTrend.subtitle4 && <h2>{authCtx?.selectedTrend.subtitle4}</h2>}
        {authCtx?.selectedTrend.description4 && <p><FormatText textArray={authCtx?.selectedTrend.description4}/></p>}
        {authCtx?.selectedTrend.subtitle5 && <h2>{authCtx?.selectedTrend.subtitle5}</h2>}
        {authCtx?.selectedTrend.description5 &&<p><FormatText textArray={authCtx?.selectedTrend.description5}/></p>}
      </div>
    </div>
    </div>
    </div>
  );
};

export default BlogIntro;
