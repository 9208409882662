import React from 'react'
import BriefCompanyIntro from './Brief Company Introduction/BriefCompanyIntro'
import TeamMember from './Team Member Profiles/TeamMember'
import CompanyValuesAndMission from './Company Values and Mission/CompanyValuesAndMission'
import CompanyExpertiseAndExperience from './Company\'s Expertise and Experience/CompanyExpertiseAndExperience'

const AboutUsPage = () => {
  return (
    <div style={{background:'#eeee'}}>
        <BriefCompanyIntro />
        <CompanyValuesAndMission />
        <TeamMember />
        <CompanyExpertiseAndExperience />
    </div>
  )
}

export default AboutUsPage