import React from 'react'
import classes from './TeamMember.module.css'
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import { team } from '../../Constants/Data';


const TeamMember = () => {
  return (
    <div className={classes.team}>
      <div className={classes.team_con}>
        <div className={classes.headerCont}>
        <div className={classes.header}>
          <h3>MEET OUR TEAM</h3>
          <p>A diverse team of skilled professionals dedicated to turning ideas into reality.</p>
        </div>
        </div>
        <div className={classes.teammembers}>
              <div className={classes.rows}>
                <div className={classes.row1}> 
          {team.slice(0,4).map((data)=>{
            return(
                <div style={{ position: "relative",width: '100%', background: 'transparent' }}>
                    <img
                      src={data.image}
                      alt=""
                      className={classes.teamImage}
                    />
                    <div className={classes.cardtext}>
                      <h3>{data.name}</h3>
                      <p >{data.jobtitle}</p>
                    </div>
                  </div>
            )
          })}
          </div>
                <div className={classes.row}> 
          {team.slice(4,8).map((data)=>{
            return(
                <div style={{ position: "relative",width: '100%',marginTop:'5%' }}>
                    <img
                      src={data.image}
                      alt=""
                      className={classes.teamImage}
                    />
                    <div className={classes.cardtext}>
                      <h3>{data.name}</h3>
                      <p >{data.jobtitle}</p>
                    </div>
                  </div>
            )
          })}
          </div>
              </div>
        </div>
        <div className={classes.swiper}>
          <div style={{width:'100%',height:'10em',position:'absolute',top:'0',marginTop:'-2%',backgroundColor:'#1d212d'}}></div>
        <Swiper   
        spaceBetween={40}
        pagination={true}
        modules={[Pagination]}
        centeredSlides={true}
        breakpoints={{
          0: {
            slidesPerView: 1.5,
            centeredSlides: true,
            spaceBetween: 50,
            },
          651: {
            slidesPerView: 2.5,
            centeredSlides: true,
            spaceBetween: 10,
          },
        951: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
        }}
        >
            {team.map((data) => {
              return (
                <SwiperSlide style={{background: 'transparent',paddingBottom:'5%', height: 'fit-content' }}>
                  <div style={{ position: "relative",width: '100%', background: 'transparent' }}>
                    <img
                      src={data.image}
                      alt=""
                      width='fit-content'
                      className={classes.teamImage}
                    />
                    <div className={classes.cardtext}>
                      <h3>{data.name}</h3>
                      <p >{data.jobtitle}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default TeamMember