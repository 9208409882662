import React from 'react'
import classes from './Privacy.module.css'
import termsimg from "../../assets/terms.png";


const privacy = [
    {
      title: '1. Information We Collect',
      text: 'We may collect the following types of personal information: Information You Provide: When you interact with our Services, you may provide us with personal information, such as your name, email address, and other contact details. Automatically Collected Information: We may automatically collect certain information about your use of our Services, including your IP address, browser type, operating system, and usage data.',
    },
    {
      title: '2. How We Use Your Information',
      text: 'We use your personal information for the following purposes:To provide and maintain our Services. To communicate with you, respond to your inquiries, and send you updates and notifications. To analyze and improve our Services and user experience. To comply with legal obligations.',
    },
    {
      title: '3. Sharing Your Information',
      text: "We do not sell, rent, or trade your personal information to third parties. We may share your information in the following circumstances: With our trusted service providers who assist us in delivering our Services. To comply with legal requirements, such as responding to subpoenas or government requests. To protect our rights, privacy, safety, or property.",
    },
    {
      title: '4. Data Security',
      text: 'We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.',
    },
    {
      title: '5. Your Choices',
      text: 'You have the following rights regarding your personal information: Access: You can request access to the personal information we hold about you. Correction: You can request corrections to inaccurate or incomplete data. Deletion: You can request the deletion of your personal information. Please contact us at info@nestedstacks.com to exercise these rights.',
    },
    {
      title: '6. Cookies and Tracking Technologies',
      text: 'We use cookies and similar tracking technologies to collect information about your use of our Services. You can manage your cookie preferences through your browser settings.',
    },
    {
      title: "7. Children's Privacy",
      text: 'Our Services are not intended for children under the age of 13. We do not knowingly collect or store personal information from children.',
    },
    {
      title: '8. Changes to This Privacy Policy',
      text: "We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The revised policy will be posted on this page with a new effective date.",
    },
    {
      title: '9. Contact Us',
      text: 'If you have any questions or concerns about this Privacy Policy, please contact us at info@nestedstacks.com.',
    }
  ]
  
  const Privacy = () => {
    return (
      <>
          <img src={termsimg} alt='' width='100%'/>
      <div className={classes.terms}>
        <div className={classes.content}>
            <div className={classes.title}>
              <h1>Privacy Policy</h1>
              <p>Last Updated: 27/01/2021</p>
            </div>
            <div className={classes.text}>
                <p><span style={{fontWeight: '700'}}>Nested Stacks</span> ("we," "us," or "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal data when you access or use our website, software, or related services (collectively, the "Services").</p>
                {privacy.map((data)=>{
                  return(
                    <div>
                      <h3>{data.title}</h3>
                      <p>{data.text}</p>
                    </div>
                  )
                })}
            </div>
        </div>
      </div>
      </>
    )
  }

export default Privacy