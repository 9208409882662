import React, { useEffect, useRef, useState } from "react";
import classes from "./Contactus.module.css";
import video from "../../assets/HeroBackground.mp4";
import facebook from "../../assets/facebookred.png";
import linkedin from "../../assets/Linkedin.png";
import instagram from "../../assets/instagramred.png";
import { Alert, Snackbar } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import emailjs from "@emailjs/browser";

const Contactus = () => {
  const [email, setEmail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorrr, setErrorrr] = useState(false);

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneno = /^\d{7,16}$/;
  const form = useRef();

  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if (name && phone && email && message) {
      console.log("donnnneeee");

      setIsSubmit(true);

      emailjs
        // .sendForm("service_ck4jm07", "template_b1dwf66", form.current, "XiTlzN1mqtZsVbs0n")
        .then(
          (result) => {
            console.log(result.text);
            form.current.reset();

            setOpen(true);
          },
          (error) => {
            setErrorrr(true);
            console.log(error.text);
          }
        );
    } else {
      setFormErrors(Validation(name, phone, email, message));
    }
  };
  useEffect(() => {
    console.log(formerrors);
    if (Object.keys(formerrors).length === 0 && isSubmit) {
      console.log(name, phone, email, message);
    }
  }, [formerrors,name, phone, email, message, isSubmit]);

  const Validation = () => {
    const errors = {};

    if (!name) {
      errors.name = "*Name is required!";
    }
    if (!email) {
      errors.email = "*Email is required and must include @!";
    }
    if (!phone) {
      errors.phone = "*Phone Number is requierd!";
    }
    if (!message) {
      errors.message = "*Message is requierd!";
    }
    return errors;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setErrorrr(false);
  };

  return (
    <div className={classes.contactus}>
      <div className={classes.CatchyHeadline}>
        <div className={classes.videoContainer}>
          <video className={classes.video} autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
        <div className={classes.taglineCont}>
          <div className={classes.Tagline}>
            <h2>Let’s Stay Connected!</h2>
          </div>
        </div></div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Email Delivered!
          <br />
          Your email was sent successfully.
        </Alert>
      </Snackbar>
      <Snackbar autoHideDuration={6000} onClose={handleClose}>
        <Alert
          open={errorrr}
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          An unexpected error occurred.Email is not sent!
          <br />
          Please try again.
        </Alert>
      </Snackbar>
      <div className={classes.contactus_con}>
        <div className={classes.content}>
          <div className={classes.form}>
              <form ref={form} onSubmit={SendEmailHandler}>
            <div className={classes.formleft}>
                <label>Name</label>
                <input
                  type="textbox"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
                {name.length <= 0 ? (
                  <div className={classes.contact_option_error}>
                    {formerrors.name}
                  </div>
                ) : (
                  ""
                )}
                <label>Phone Number</label>
                <input
                  type="textbox"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
                {!phoneno.test(phone) ? (
                  <div className={classes.contact_option_error}>
                    {formerrors.phone}
                  </div>
                ) : (
                  ""
                )}
                <label>E-mail</label>
                <input
                  type="textbox"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!regex.test(email) ? (
                  <div className={classes.contact_option_error}>
                    {formerrors.email}
                  </div>
                ) : (
                  ""
                )}
                <label>Query</label>
                <textarea type="text" name="message" onChange={(e)=>{setMessage(e.target.value)}}/>
                {message.length <= 0 ? (
                    <div className={classes.contact_option_error} style={{marginBottom: '2em'}}>
                      {formerrors.message}
                    </div>
                  ) : (
                    ""
                  )}
                <button type="submit" className={classes.btn}>
                  Submit
                  <span style={{ margin: "2px 0px 0 80px" }}>
                    <BsArrowRight />
                  </span>
                </button>
            </div>
              </form>
            <div className={classes.formright}>
              <div className={classes.socials}>
                <div className={classes.location}>
                  <h1>What To Do!</h1>
                  <p style={{ marginBottom: "10%" }}>
                    Whether you’ve got a question about Nested Stacks or would
                    like to introduce us to your company, we’d love to hear from
                    you. Just use the form on this page to drop us a line and a
                    member of our team will be in touch as soon as possible.
                  </p>
                  <h1>NESTED STACKS Holding!</h1>
                  <p>Tayouneh Chiyah, Beirut Lebanon</p>
                  <p>+961 70 916987</p>
                  <p>info@nestedstacks.com</p>
                </div>
                <div className={classes.icons}>
                  <h1>FOLLOW OUR SOCIALS</h1>
                  <div className={classes.links}>
                    <img src={facebook} alt="" onClick={()=>window.open('https://www.facebook.com', '_blank')}/>
                    <img src={instagram} alt="" onClick={()=>window.open('https://www.instagram.com', '_blank')}/>
                    <img src={linkedin} alt="" onClick={()=>window.open('https://www.linkedin.com', '_blank')}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
